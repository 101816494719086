// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../css/application';

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

$(function() {
  if (document.getElementById('nav-toggle')) {
    document.getElementById('nav-toggle').onclick = function(){
      document.getElementById("nav-content").classList.toggle("hidden")
      document.getElementById("open").classList.toggle("hidden")
      document.getElementById("close").classList.toggle("hidden")
    }
  }
})

const images = require.context('../images/', true)